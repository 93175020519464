import React from 'react'
import { graphql } from 'gatsby'
import { Container } from 'theme-ui'
import Layout from '@solid-ui-layout/Layout'
import Seo from '@solid-ui-components/Seo'
import Divider from '@solid-ui-components/Divider'
import ModalWithTabs from '@solid-ui-blocks/Modal/Block01'
import ModalSimple from '@solid-ui-blocks/Modal/Block02'
import Header from '@solid-ui-blocks/Header/Block01'
import Hero from '@solid-ui-blocks/Hero/Block02'
import Features from '@solid-ui-blocks/Features/Block06'
import HowItWorks from '@solid-ui-blocks/FeaturesWithPhoto/Block04'
import FeatureTabOne from '@solid-ui-blocks/FeaturesWithPhoto/Block05'
import FeatureTabTwo from '@solid-ui-blocks/FeaturesWithPhoto/Block06'
import FeatureTabThree from '@solid-ui-blocks/FeaturesWithPhoto/Block01'
import Pricing from '@solid-ui-blocks/Pricing/Block01'
import WhyChooseUs from '@solid-ui-blocks/Features/Block04'
import GetStarted from '@solid-ui-blocks/Stats/Block01'
import Testimonials from '@solid-ui-blocks/Testimonials/Block01'
import Companies from '@solid-ui-blocks/Companies/Block01'
import Blog from '@solid-ui-blocks/Blog/Block01'
import Footer from '@solid-ui-blocks/Footer/Block01'
import { normalizeBlockContentNodes } from '@blocks-helpers'
import theme from './_theme'
import styles from './_styles'
import WithRecentPosts from '@solid-ui-blocks/WithRecentPosts'
import CookieConsent from 'react-cookie-consent'

const IndexPage = props => {
  const { allBlockContent } = props.data
  const content = normalizeBlockContentNodes(allBlockContent?.nodes)

  return (
    <Layout theme={theme} {...props}>
      <Seo title='Gemmoprice' />
      {/* Modals */}
      <ModalWithTabs content={content['authentication']} reverse />
      <ModalWithTabs content={content['contact']} />
      <ModalSimple content={content['advertisement']} />
      {/* Blocks */}
      <Header content={content['header']} />
      <Divider space='5' />
      <Container variant='full' sx={styles.heroContainer}>
        <Hero content={content['hero']} />
      </Container>
      <Divider space='2' />
      <Features content={content['features']} />
      <Divider space='2' />
      <Divider space='2' />
      <Container variant='wide' sx={styles.featuresContainer}>
        <HowItWorks content={content['how-it-works']} />
        <Divider space='5' />
        <Divider space='5' />
        <FeatureTabOne content={content['feature-tab-one']} reverse />
        <Divider space='5' />
        <Divider space='5' />
        <FeatureTabTwo content={content['feature-tab-two']} />
        <Divider space='5' />
        <Divider space='5' />
        <FeatureTabThree content={content['feature-tab-three']} reverse />
      </Container>
      <Divider space='4' />
      <Divider space='2' />
      <WhyChooseUs content={content['why-choose-us']} />
      <Divider space='4' />
      <Divider space='2' />
      <Pricing content={content['pricing']} />
      <Divider space='2' />
      <Divider space='2' />
      <Container variant='full' sx={styles.testimonialsContainer}>
        <Testimonials content={content['testimonials']} />
      </Container>
      <Companies content={content['companies']} />

      <Divider space='4' />
      {/*<Blog content={content['latest-blogs']} /> */}
      <Footer content={content['footer']} />
      <CookieConsent
          location="top"
          buttonText="ALLOW ALL COOKIES"
          declineButtonText="Decline"
          style={{ background: "#ff6600" }}
          buttonStyle={{ background: "#ffffff", color: "#ff6600", fontSize: "16px", fontWeight: "bold" }}
          cookieName="gatsby-gdpr-google-analytics" overlay>
          By clicking “Allow all cookies”, you agree to the storing of cookies on your device to enhance site navigation, analyze site usage, and assist in our marketing efforts.
      </CookieConsent>
    </Layout>
  )
}

export const query = graphql`
  query homepageSaasBlockContent234 {
    allBlockContent(filter: { page: { in: ["site/index", "shared"] } }) {
      nodes {
        ...BlockContent
      }
    }
  }
`
export default IndexPage
